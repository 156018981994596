$(document).on('turbolinks:load', function() {
  
    function hideInput(elem) {
      elem.children().find('input').first().attr('disabled', 'disabled');
      elem.hide()
    }
  
    function showInput(elem) {
      elem.children().find('input').first().removeAttr('disabled');
      elem.show()
    }
  
    $('input[type=radio][name=discountChoices]').change(function() {
      if (this.value == 'amount') {
        hideInput($('#percent-off-field'))
        showInput($('#amount-off-field'))
      } else {
        hideInput($('#amount-off-field'))
        showInput($('#percent-off-field'))
      }
    });
  
    $("#billing_coupon_duration").change(function() {
        if ($(this).val() == 'repeating') {
          showInput($('#duration-repeating-fields'))
        } else {
          hideInput($('#duration-repeating-fields'))
        }
    });
  });