/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/**
 * Refers to app/javascript/stylesheets/application.scss (or application.css)
 * Note that we don't need to preface this path with "app/javascript" due to the `source_path` config set in config/webpacker.yml. Magical!
 * The file extension can be left off due to the `extensions` config in config/webpacker.yml.
 */


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

window.jQuery = window.$ = require('jquery')
require("bootstrap")

// Charts
require("chartkick")
require("chart.js")

// Input Formatting
require("cleave.js");
require("./phone-type-formatter.us")

import "core-js/stable"
import "regenerator-runtime/runtime"
import "./e-signature"
import "./stripe_checkout"
import "./account"
import "./coupon"
import "./subscription"
import "./side_nav"
import "./validation"
import "./shims/matches"
import "./shims/closest"
import "./placeholder_label"

// Stimulus controllers
import "controllers"



document.addEventListener("DOMContentLoaded", function (event) {
    window.__AMOUNT_INPUT_MASK__ = {
        prefix: "",
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralPositiveOnly: true,
    };

    window.__PHONE_INPUT_MASK__ = {
        phone: true,
        phoneRegionCode: 'US',
        delimiter: '-'
    }
})


$(document).on('turbolinks:load', function () {
    $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        animation: true
    })

    // Currency Input Mask
    document.querySelectorAll("input.currency-input-mask").forEach(function (el) {
            new Cleave(el, window.__AMOUNT_INPUT_MASK__);
    });

    // Phone Input Mask
    document.querySelectorAll("input.phone-input-mask").forEach(function (el) {
            new Cleave(el, window.__PHONE_INPUT_MASK__);

    });
})

// Rich text editor
var Trix = require("trix")
require("@rails/actiontext")
