import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    super.connect()
    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect(){
    this.stopRefreshing();
  }

  load() {
    this.stimulate()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing(){
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}