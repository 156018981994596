import ApplicationController from './application_controller'

export default class extends ApplicationController {

  initialize() {
    $("#product-select select").on("change", () => {
      this.check_select()
    })
    // Select the first option in the dropdown
    $("#product-select select").val($("#product-select select option:first").val());
    this.check_select()
  }

  check_select(){
    let customPrice = $(".custom-payment :input[type=text]")
    let paymentDesc = $(".payment-desc :input[type=text]")
    let selected = $("#product-select select option:selected").val()

    if(selected === "-1"){
      $(".statement-descriptor").show()
      $(".custom-payment").show()
      $(".payment-desc").show()
      customPrice.prop('disabled', false).prop('required', true)
      paymentDesc.prop('disabled', false).prop('required', true)
    }else{
      $(".statement-descriptor").hide()
      $(".custom-payment").hide()
      $(".payment-desc").hide()
      customPrice.prop('disabled', true).prop('required', false)
      paymentDesc.prop('disabled', true).prop('required', false)
    }
  }
}