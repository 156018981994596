/**
 * Matches selector polyfill
 * Cross-browser matches selector implementation
 * @source https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
 * @license MIT
 **/

if ( !Element.prototype.matches ) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
