import ApplicationController from './application_controller'
import Trix from 'trix'

export default class extends ApplicationController {

  static targets = ["editor"]

  connect() {
  }

  // trixChange(event) {
  //   var foundItem = false
  //   var foundStart = -1
  //   var foundText = ""

  //   if (event.target == this.editorTarget) {
  //     let stringDoc = this.editorTarget.editor.getDocument().toString()

  //     for (var count = 0; count<stringDoc.length; count++) {
  //       let letter = stringDoc[count];
  //       if (letter == "{") {
  //         if (foundItem) {
  //           foundText += letter
  //           let tag = (foundText in this.supportedTargets)

  //           if (tag) {
  //             console.log("Tag found.")
  //             var newTag = foundText.replace(":", "{{")
  //             newTag = newTag.replace(":", "}}")
  //             this.editorTarget.editor.setSelectedRange([foundStart, count + 1])
  //             this.editorTarget.editor.insertHTML(`<span style="color: green;">${newTag}</span>`)
  //             this.editorTarget.editor.deactivateAttribute("green")
  //             return

  //           } else {
  //             foundItem = false
  //             foundStart = -1
  //             foundText = ""
  //           }
  //         } else {
  //           foundItem = true
  //           foundStart = count
  //           foundText = letter
  //         }
  //       } else if (foundItem) {
  //         // If we come across a space, it's not a supported emoji, so reset
  //         if (letter == " ") {
  //           foundItem = false
  //           foundStart = -1
  //           foundText = ""
  //         } else {
  //           console.log("Adding letter to found text.")
  //           foundText += letter
  //         }
  //       }
  //     }
  //   }
  // }

}
