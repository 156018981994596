import ApplicationController from './application_controller'

import Rails from "@rails/ujs";

export default class extends ApplicationController {

  static targets = ["products", "plans"]

  setButtonView() {
    var selectedPlan = $(this.plansTarget).find(':checked');
    var contractRequired = selectedPlan.data('contract')
    if (contractRequired) {
      this._showSignAgreementButton()
    } else {
      this._showCreateSubscriptionButton()
    }
  }

  updatePricingPlans() {
    Rails.ajax({
      type: "GET",
      url: this._productPlansUrl(),
      success: (data) => {
        this._refreshPricingPlansValues(data)
        this.setButtonView()
      }
    })
  }

  get product_id() {
    this.productTarget.value
  }

  _productPlansUrl() {
    var selectedProduct = $(this.productsTarget).find(':checked');
    var products_url = $(this.productsTarget).data('products-url')
    return products_url + "/" + selectedProduct.val() + "/plans?recurring=true"
  }
  
  _refreshPricingPlansValues(data) {
    $('#planContainer').show()
    var plans = $(this.plansTarget)
    plans.empty()
    $.each(data["plans"], function(index, val) {
      plans.append("<option data-contract=\"" + val["contract_required"] + "\" value=\"" + val["id"] + "\">" + val["pricing_description"] + "</option>")
    });
  }

  _showSignAgreementButton() {
    $('#contractButtons').addClass('show')
    $('#baseButtons').removeClass('show')
  }

  _showCreateSubscriptionButton() {
    $('#baseButtons').addClass('show')
    $('#contractButtons').removeClass('show')
  }

}