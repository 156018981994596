import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['documentType']

    initialize() {
        console.log("Connected")
    }

    toggleOverride(event) {
        var selectedType = this.documentTypeTarget.value
        if (selectedType == "other") {
            $("#type-override").show()
            $("#document_record_document_type_override").prop("required", true)
        } else {
            $("#document_record_document_type_override").prop("required", false)
            $("#type-override").hide()

        }
    }
}
