
$(document).on('turbolinks:load', function() {
    // Only evaluate on accounts #show.
    if (!$("body.accounts.show").length && !$("body.payments.index").length) {
        return
    }
    // Create a Stripe client.
    // TODO - Log statement seems to fix race condition... Wierd.
    console.log('chocka: pk_live_vkgIlAjQVSrGCVusxZ89LUui00gGENXebP');
    var stripe = Stripe('pk_live_vkgIlAjQVSrGCVusxZ89LUui00gGENXebP');
    var elements = stripe.elements();
    $('#addPayment').on('click', function() {
        // Don't attempt to mount again if it's already mounted.
        if ($('.StripeElement').length > 0) {
            return
        }
        // Create an instance of Elements.
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
            base: {
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#7091A9'
                }
            },
            invalid: {
                color: '#ef394b',
                iconColor: '#fa755a'
            },
            success: {
                color: '#19BF81',
                iconColor: '#19BF81'
            }
        };
        // Create an instance of the card Element.
        var card = elements.create('card', { style: style });
        // Add an instance of the card Element into the `card-element`
        card.mount('#card-element');
        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // Handle form submission.
        //   var form = document.getElementById('payment-form');
        var submitButton = document.getElementById('payment-submit-button')
        submitButton.addEventListener('click', function(event) {
            event.preventDefault();
            submitButton.setAttribute('disabled', true);
            submitButton.value = 'Please wait...';

            stripe.createToken(card).then(function(result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    submitButton.removeAttribute('disabled');
                    submitButton.value = 'Create payment';
                } else {
                    // Send the token to your server.
                    stripeTokenHandler(result.token);
                }
            });
        });

        function stripeTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment_form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripe_token');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);
            // Submit the form
            form.submit();
        }
    });


    $('#addCard').on('click', function() {
        // Don't attempt to mount again if it's already mounted.
        if ($('.StripeElement').length > 0) {
            return
        }
        // Create an instance of Elements.
        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        // Create an instance of the card Element.
        var card = elements.create('card', { style: style });
        // Add an instance of the card Element into the `card-element`
        card.mount('#card-element');
        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // Handle form submission.
        //   var form = document.getElementById('payment-form');
        var submitButton = document.getElementById('payment-method-button')
        submitButton.addEventListener('click', function(event) {
            event.preventDefault();
            submitButton.setAttribute('disabled', true);
            submitButton.value = 'Please wait...';
            stripe.createToken(card).then(function(result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    stripeTokenHandler(result.token);
                }
            });
        });
    });

    function stripeTokenHandler(token) {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.getElementById('payment-method-form');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripe_token');
        hiddenInput.setAttribute('value', token.id);
        form.appendChild(hiddenInput);
        // Submit the form
        form.submit();
    }
});
