import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['featureCheckbox']

  toggleFeature(event) {
    var identifier = $(event.currentTarget).data('identifier')
    var isChecked = $(event.currentTarget).is(':checked')

    if (isChecked) {
      this.stimulate('Feature#enable_feature', identifier)
    } else {
      this.stimulate('Feature#disable_feature', identifier)
    }
  }

}