import ApplicationController from './application_controller'

export default class extends ApplicationController {

  resync(event) {
    let html = event.currentTarget;
    event.stopPropagation()
    $(html).attr('disabled', true).text("Syncing...")
    this.stimulate('AttendanceCalendar#resync', this.locationId, this.calendarId)
    .then(() => {
      $(html).attr('disabled', false).text("Resync")
    })
    .catch((err) => {
      console.error(err)
      $(html).attr('disabled', false).text("Resync")
    })
  }

  get locationId(){
    return this.data.get('locationId')
  }

  get calendarId() {
    return this.data.get('id')
  }
}