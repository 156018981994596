$(document).on('turbolinks:load', function() {
    if (!$("body.waivers.new").length && !$("body.contracts.new").length && !$("body.signed_copies.new").length && !$("body.public.new").length) {
        return
    }

    var canvas = document.getElementById("signature-pad");
    if (!canvas) {
        console.log("No canvas found. returning.")
        return
    }
    var signaturePad = new SignaturePad(canvas, {
        // min/max line width
        minWidth: 0.5,
        maxWidth: 2.5,
        // weight used to modify new velocity based on the previous velocity
        velocityFilterWeight: 0.7,
        // draw the next point at most once per every x milliseconds
        throttle: 16,
        // min distance
        minDistance: 5,
        // dot size
        dotSize: 2,
        // line color
        penColor: 'black'

    });

    window.addEventListener("resize", drawCanvas);
    drawCanvas();

    var saveButton = document.getElementById("signature-save");
    var clearButton = document.getElementById("signature-clear");
    signaturePad.on();

    //Save the waiver sig as png to hidden variable
    if (saveButton) {
        saveButton.addEventListener('click', () => {
            if (!signaturePad.isEmpty()) {
                var data = signaturePad.toDataURL();
                document.getElementById("signature_uri").value = data;
            }
        });
    }

    //Clear the signature pad
    clearButton.addEventListener('click', () => {
        signaturePad.clear();
    });

    function resizeCanvas() {
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }

    function drawCanvas() {
        resizeCanvas();
        signaturePad = new SignaturePad(canvas);
        if (!signaturePad.isEmpty()) {
            signaturePad.fromDataURL();
        }
    }
});
