import ApplicationController from './application_controller'

let totalChecked = 0;

export default class extends ApplicationController {

  static targets = ['bulkSelect', 'bulkAction', 'studentCheckbox', 'searchInput', "checkbox"]


  afterReflex() {
    this._toggleEmptyContainer()
  }

  search() {
    var search = $(this.searchInputTarget).val()
    this.stimulate('AttendanceEvent#search', search)
  }

  signIn(event) {
    event.stopPropagation()
    $(event.currentTarget).attr('disabled', true).text("Signing in...")
    var studentId = $(event.currentTarget).data('student-id')

    this.stimulate('AttendanceEvent#sign_in', studentId)
      .then(() => {
        this._deleteStudentRows([studentId], true)
        this.toggleBulkSelect()
      })
  }

  signOut(event) {
    event.stopPropagation()
    $(event.currentTarget).attr('disabled', true).text("Signing out...")
    var studentId = $(event.currentTarget).data('student-id')

    this.stimulate('AttendanceEvent#sign_out', studentId) 
      .then(() => {
        this._deleteStudentRows([studentId], false)
        this.toggleBulkSelect()
      })
  }

  signInSelected(event){
    event.stopPropagation()
    var btn = $(event.currentTarget)
    btn.attr('disabled', true).text("Signing in...")
    var studentIds = this.selectedStudentIds
    totalChecked = 0;
    
    this.stimulate('AttendanceEvent#bulk_sign_in', studentIds)
      .then(() => {
        $(this.bulkSelectTarget).prop('checked', false)
        this._deleteStudentRows(studentIds, true)
        btn.attr('disabled', false).text("Sign in")
      })
  }

  signOutSelected(event){
    event.stopPropagation()
    var btn = $(event.currentTarget)
    btn.attr('disabled', true).text("Signing out...")
    var studentIds = this.selectedStudentIds
    totalChecked = 0;
    
    this.stimulate('AttendanceEvent#bulk_sign_out', studentIds)
    .then(() => {
      $(this.bulkSelectTarget).prop('checked', false)
      this._deleteStudentRows(studentIds, false)
      btn.attr('disabled', false).text("Sign out")
    })
  }

  toggleSelect(event) {
    var checkboxTarget = $(event.currentTarget).parent('tr').find('input[type=checkbox')
    checkboxTarget.prop('checked', !checkboxTarget.is(':checked')) 
  }

  toggleBulkSelect(){
    var checkAll = $(this.bulkSelectTarget).is(':checked')
    this._toggleStudentCheckboxes(checkAll)

    totalChecked = this.studentCheckboxTargets.length
    if (checkAll) {
      this._showBulkAction()
    } else {
      this._hideBulkAction()
      totalChecked = 0
    }    
  }

  toggleBulkAction(event){
    if (typeof event == undefined) {
      var isChecked = $(event.currentTarget).is(':checked')
      isChecked ? totalChecked++ : totalChecked--
    } else {
      totalChecked = this._calculateTotalChecked()
    }
    totalChecked == 0 ? this._hideBulkAction() : this._showBulkAction()
  }

  _deleteStudentRows(studentIds, signIn) {
    var arr = []
    var context = this
    var prefix = signIn ? "tr#signIn-" : "tr#signOut-" 
    $(studentIds).each(function() {
      arr.push(prefix+this)
    })

    $(arr.join(', ')).fadeOut(200).remove().promise().done(function(){
      context._hideBulkAction()
    })
 
  }

  _toggleEmptyContainer() {
    if ($('#attendance-signed-student-list tr.student').length > 0) {
      $('#signed-student-list-empty-container').hide()
      $('#signed-student-container').show()
    } else {
      $('#signed-student-container').hide()
      $('#signed-student-list-empty-container').show()
    }

    if ($('#attendance-student-list tr.student').length > 0) {
      $('#student-list-empty-container').hide()
      $('#student-list-container').show()
    } else {
      $('#student-list-container').hide()
      $('#student-list-empty-container').show()
    }
  }

  _hideBulkAction() {
    if (this.hasBulkActionTarget) {
      $(this.bulkActionTarget).prop('checked', false)
      $(this.bulkActionTarget).hide()
    }
  }

  _showBulkAction(){
    if (this.hasBulkActionTarget) {
      $(this.bulkActionTarget).show() 
    }
  }

  _toggleStudentCheckboxes(checked) {
    $(this.studentCheckboxTargets).each(function() {
      $(this).prop('checked', checked)
    });
  }

  _calculateTotalChecked(){
    var count = 0
    $(this.studentCheckboxTargets).each(function() {
      if ($(this).is(':checked')) {
        count++
      }
    });
    return count
  }

  get selectedStudentIds() {
    var studentIds = []
    $(this.studentCheckboxTargets).each(function() {
      if ($(this).is(':checked')) {
        studentIds.push($(this).val())
      }
    })
    return studentIds
  }
}
