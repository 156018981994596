import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['contractRequired']

  initialize() {
    this.toggleDisable()
    this.checkRecurringFields();
    $("#recurring-container :input[type=checkbox]").on('change', () => {
      this.checkRecurringFields();
    })
  }

  toggleDisable() {
    if (this.hasContractRequiredTarget) {
      var isChecked = $(this.contractRequiredTarget).is(':checked')

      if (isChecked) {
        $("#contractTerms").addClass('show')
        $("#contractTerms :input").attr("disabled", false)

      } else {
        $("#contractTerms :input").attr("disabled", true)
        $("#contractTerms").removeClass('show')
      }
    }
  }

  checkRecurringFields() {
    let recurringContainer = $("#recurring-container :input[type=checkbox]")
    let recurringFields = $(".recurring-fields")
    let recurringText = $("#currency-amount")

    //Conditional fields
    let billingPeriodSelect = $('#billing_plan_billing_period')
    let trailPeriod = $('#billing_plan_trial_period_days')

    if(recurringContainer.is(':checked')){
      billingPeriodSelect.prop('disabled', false).prop('required', true)
      recurringFields.show()
      recurringText.show()
    }else{
      billingPeriodSelect.prop('disabled', true).prop('required', false)
      recurringFields.hide()
      recurringText.hide()
    }
  }
}
