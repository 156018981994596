$(document).on('turbolinks:load', function() {

    // This is gross, need to clean this 
    // Toggles the side-nav on smaller view ports.
    $('#side-nav-toggle').on('click', function(){
        if ($(".nav-side-menu").css('visibility') == "visible") {
           $("#main").addClass("sidenav-collapsed", 1000);
           $('#nav-side-menu-parent').addClass('fadeOut', 1000)
           $('#nav-side-menu-parent').removeClass('fadeIn', 1000)
        } else {
           $("#main").removeClass("sidenav-collapsed", 1000);
           $('#nav-side-menu-parent').addClass('fadeIn', 1000)
           $('#nav-side-menu-parent').removeClass('fadeOut', 1000)
        } 
     });


     // Nav toggles
    $('.billing-btn').on('click', function () {
      container = $('.billing-container');
      if (container.hasClass('show') && !container.children('li').hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    });
  
    $('.inventory-btn').on('click', function () {
      container = $('.inventory-container');
      if (container.hasClass('show') && !container.children('li').hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    });
});