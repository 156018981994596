/**
 * Validation 0.1.0
 * Form validation module
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

// Public API object
const validation = (() => {

  // Suppress default message bubbles
  document.addEventListener('invalid', function (event) {
    event.preventDefault()
  }, true)

  // Run delegated `on blur` validation checks
  document.addEventListener('blur', blurHandler, true)

  // Run delegated `on submit` validation checks
  document.addEventListener('click', submissionHandler, false)

  return {
    check: check,
    warn: warn,
    message: message,
    submission: submissionHandler
  }
})()

// Check validity handler function
function check(element) {

  // Declare status indicator element
  const indicator = element.closest('label') || element

  // If element only contains whitespace, strip value
  if (element.value && !element.value.replace(/\s/g, '').length) element.value = ''

  // Account for custom rejected values
  if (element.getAttribute('data-reject') === element.value) {
    element.setCustomValidity(element.getAttribute('data-reject-message'))
  } else if (element.getAttribute('data-reject') !== element.value) {
    element.setCustomValidity('')
  }

  // Remove pre-existing validation message
  message('hide', element)

  // Check validity
  const validity = element.checkValidity()

  // If the element has a value but is invalid
  if (element.value && !validity) {
    indicator.removeAttribute('data-valid')
    indicator.setAttribute('data-invalid', '')
  }

  // If the element has a value and is valid
  else if (element.value && validity) {
    indicator.removeAttribute('data-invalid')
    indicator.setAttribute('data-valid', '')
  }

  // If the element has no value
  else if (!element.value) {
    indicator.removeAttribute('data-valid')
    indicator.removeAttribute('data-invalid')
  }

  return validity
}

// Validation message handler functions
function message(action, element) {

  // Scoped variables
  const parentForm = element.closest('form')
  const oldMessage = parentForm.querySelector('[data-validation-message]')
  const newMessage = element.getAttribute('data-reject') === element.value ? element.validationMessage : element.getAttribute('data-message') ? element.dataset.message : element.validationMessage

  // Hide old message
  const hideMessage = function () {
    oldMessage.parentNode.removeChild(oldMessage)
  }

  // Show new message
  const showMessage = function () {

    // Find the element's parent label
    const labelParent = element.closest('label')

    // If it doesn't exist, abort
    if (!labelParent) return false

    // Otherwise, create and append the validation message
    labelParent.insertAdjacentHTML('beforeend', `<aside data-validation-message data-tooltip data-tooltip-position="${element.getAttribute('data-message-position') || 'down'}" data-tooltip-visible="true"><span data-tooltip-content>${newMessage}</span></aside>`)

  }

  // If hide action is passed hide old message
  if (oldMessage && action === 'hide') hideMessage()

  // If show action is passed and no old message exists, show new message
  if (action === 'show' && !oldMessage) {
    showMessage()
  }

  // If show action is passed and old message exists
  else if (action === 'show' && oldMessage) {

    // Make sure old message is not on currently invalid element, then hide old message and show new message
    if (oldMessage.parentNode !== element.parentNode) {
      hideMessage()
      showMessage()
    }

    // Otherwise update message text on currently invalid element
    else {
      oldMessage.childNodes[0].textContent = newMessage
    }
  }
}

// Blur validation handler
function blurHandler(event) {

  // Cache target
  const element = event.target

  // Only run on required, non-submit inputs
  if (!element.hasAttribute('required') || !element.matches('[required]:not([type=submit])')) return

  // Check validity and set appropriate attribute
  check(element)
}

// Validation warning handler
function warn(element) {

  // If invalid is not hidden, focus it
  if (element.style.display !== 'none') {
    if (typeof element.select === 'function') {
      element.select()
    } else {
      element.focus()
    }
  }

  // Otherwise focus its immediate sibling (mostly used for upload buttons)
  else {
    element.nextSibling.select()
  }

  // Show error message
  message('show', element)
}

// Submission validation handler function
function submissionHandler(event) {

  // Only run on submission
  if (!event.target || !event.target.matches('[type=submit]')) return

  // Scoped variables
  const invalidForm = event.target.hasAttribute('form') ? document.getElementById(event.target.getAttribute('form')) : event.target.closest('form')
  const invalidInput = invalidForm.querySelector('[required]:invalid')

  // If invalid element found
  if (invalidInput) {

    // Set indicator to parent label if it exists
    const indicator = invalidInput.closest('label') || invalidInput

    // Prevent default behavior
    event.preventDefault()

    // Toggle classes
    indicator.removeAttribute('data-valid')
    indicator.setAttribute('data-invalid', '')

    // Show validation warning
    warn(invalidInput);

    // Prevent Safari submission
    return false
  }
}