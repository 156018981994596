/**
 * Placeholder Label 0.0.1
 * Faux placeholder label module
 * @author Kyle Foster (@hkfoster)
 * @license MIT
 **/

// Public API object
const placeholderLabel = (() => {

  // Blur handler
  function blurHandler(event) {
    const element = event.target
    if (!element || element.tagName.toLowerCase() !== 'select') return false
    
    // If element only contains whitespace, strip value
    if (element.value && !element.value.replace(/\s/g, '').length) element.value = ''

    // Set selected attribute
    if (element.value) {
      element.setAttribute('data-selected', '')
    } else {
      element.removeAttribute('data-selected')
    }
    
  }

  // Load handler
  function loadHandler() {
    const selectedOptions = document.querySelectorAll('[selected]')
    if (!selectedOptions.length) return false

    // Set selected attributes
    selectedOptions.forEach(option => option.closest('select').setAttribute('data-selected', ''))
  }

  // Run delegated listeners
  document.addEventListener('blur', blurHandler, true)
  document.addEventListener('turbolinks:load', loadHandler)

})()