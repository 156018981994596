/**
 * Closest selector polyfill
 * Cross-browser closest ancestor to match selector implementation
 * @source https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 * @license MIT
 **/

if ( !Element.prototype.closest ) {
  Element.prototype.closest = function( selector ) {
    var element = this;
    if ( !document.documentElement.contains( element ) ) return null;
    do {
      if ( element.matches( selector ) ) return element;
      element = element.parentElement;
    } while ( element !== null );
    return null;
  };
}