import ApplicationController from './application_controller'
import Sortable, { Swap } from "sortablejs"

export default class extends ApplicationController {

  connect() {
    super.connect()

    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      group: "document-fields",
      animation: 150,
      swapThreshold: 1,
      easing: "cubic-bezier(0.33, 1, 0.68, 1);", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
      draggable: "div.col.builder-field",
      delayOnTouchOnly: true,
      delay: 100,
      forceFallback: true,
      emptyInsertThreshold: 15,
      invertSwap: true,
      invertedSwapThreshold: 1.5,
      dragoverBubble: false,
      ghostClass: "sortable-ghost",  // Class name for the drop placeholder
      dragClass: "sortable-drag",  // Class name for the dragging item
      chosenClass: "sortable-chosen",  // Class name for the chosen item
    })
  }

  end(event) {
    event.stopPropagation()
    var locationId = $(event.target).data('location-id')
    var documentId = $(event.target).data('document-id')
    var fieldId = $(event.item).data('field-id')
    var targetRow = $(event.to).data('row-id')
    var targetIndex = event.newDraggableIndex
    var sourceIndex = event.oldDraggableIndex
    var sourceRow = $(event.target).data('row-id')

    // console.log("Location: " + locationId)
    // console.log("Document: " + documentId)
    // console.log("Field: " + fieldId)
    // console.log("Target Row: " + targetRow)
    // console.log("Target index: " + targetIndex)

    this.stimulate('DocumentField#update_position', locationId, documentId, fieldId, targetRow, sourceRow, targetIndex, sourceIndex)
  }

}


