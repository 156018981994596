import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["landing", "label", "type", "placeholder", "required", "row", "editInput"]

  stubField(event) {
    event.stopPropagation()
    var input = $(event.currentTarget)
    var field_type = input.data('field-type')
    var field_identifier = input.data('field-identifier')
    this.stimulate('DocumentField#stub', this.locationId, this.documentId, field_identifier, field_type).then(promise => {
        const { data, element, event, payload, reflexId } = promise
        $('#documentNewField').modal('toggle')
        window.scrollTo(0, document.body.scrollHeight);
      }).catch(promise => {
        const { data, element, event, payload, reflexId, error } = promise
        console.log(error)
      })
  }

  get locationId(){
    return this.data.get('locationId')
  }

  get documentId(){
    return this.data.get('documentId')
  }

  openModal(event) {
    event.stopPropagation()
    $('#documentNewField').modal('toggle')
  }

  toggleEditModal(event) {
    event.stopPropagation()
    var input = $(event.currentTarget)
    var target = `#documentEditField-${input.data('field-id')}`
    $(target).modal('toggle')
  }

}
