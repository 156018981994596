$(document).on('turbolinks:load', function() {
    if ($('#subscription_cancelation_policy_cancel_on_custom_date').is(':checked')) {
        $('#cancel-at').show();
        $('#subscription_cancel_at').removeAttr('disabled');
    } else {
        $('#cancel-at').hide();
        $('#subscription_cancel_at').attr('disabled', 'disabled');
    }
    $('#cancelation_form input[type=radio]').on('click', function(){
        if ($(this).prop('id') == 'subscription_cancelation_policy_cancel_on_custom_date') {
            $('#cancel-at').show();
            $('#subscription_cancel_at').removeAttr('disabled');
        } else {
            $('#cancel-at').hide();
            $('#subscription_cancel_at').attr('disabled', 'disabled');
        }   
    });
});